import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import DomainIpConfigurationForm from "./DomainIpConfigurationForm";
import RegisteredCheckBoxField from "./components/RegisteredCheckBoxField";
import PropTypes from "prop-types";


const BoardConfigurationFieldsForm = ({control, errors, register, watch, listsOnly = false}) =>
    <Fragment>
        <DomainIpConfigurationForm
            control={ control }
            errors={ errors }
            watch={ watch }
        />
        <Row>
            <Col>
                <div className="form__form-group form__form-group-field">
                    { !listsOnly &&
                        <div className={ 'me-4' }>
                            <RegisteredCheckBoxField
                                name={ 'dropInternet' }
                                value={ watch('dropInternet') }
                                onChange={ (value) => {
                                } }
                                defaultChecked={ false }
                                label={ "Disable internet connection on this board" }
                                register={ register }
                            />
                        </div>
                    }
                    <div className={ 'me-4' }>
                        <RegisteredCheckBoxField
                            name={ 'strictDnsEnabled' }
                            value={ watch('strictDnsEnabled') }
                            onChange={ (value) => {
                            } }
                            defaultChecked={ false }
                            label={ "Strict Dns" }
                            register={ register }
                        />
                    </div>
                    <div className={ 'me-4' }>
                        <RegisteredCheckBoxField
                            name={ 'override24GHzEnabled' }
                            value={ watch('override24GHzEnabled') }
                            onChange={ (value) => {
                            } }
                            defaultChecked={ false }
                            label={ "2.4 GHz Wifi Band" }
                            register={ register }
                        />
                    </div>
                </div>
            </Col>
        </Row>
    </Fragment>


BoardConfigurationFieldsForm.propTypes = {
            control: PropTypes.shape().isRequired,
            errors: PropTypes.shape().isRequired,
            listsOnly: PropTypes.bool
        }

            export default BoardConfigurationFieldsForm;
