import React, {Fragment, useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import BoardConfigurationSubmit from "../../../shared/components/form/BoardConfigurationSubmit";
import {getChangedFields, getErrorMessageFromResponse, getListBlockConfiguration} from "../../../shared/helpers";
import {useBoardConfigQuery} from "../../../queries/customer/CustomerBoardConfig";
import {useBoardBlockListDomainQuery} from "../../../queries/blocklist/board/BoardBlockListPolicyDomain";
import {useBoardBlockListIPQuery} from "../../../queries/blocklist/board/BoardBlockListPolicyIP";
import VpnZeroTrustBoardSubmit from "../../Configuration/Boards/components/VpnZeroTrustBoardSubmit";
import {useUpdateBoardConfigMutation} from "../../../mutations/customer/UpdateCustomerBoardConfig";
import {useUpdateBoardBlockListPolicyDomainMutation} from "../../../mutations/blocklist/board/UpdateBoardBlockListPolicyDomain";
import {useUpdateBoardBlockListPolicyIPMutation} from "../../../mutations/blocklist/board/UpdateBoardBlockListPolicyIP";
import {toast} from "react-hot-toast";
import {useCompanyBlockListDomainQuery} from "../../../queries/blocklist/company/CompanyBlockListPolicyDomain";
import {useCompanyBlockListIPQuery} from "../../../queries/blocklist/company/CompanyBlockListPolicyIP";
import {areAllMutation, areAnyMutation, getFirstMutationError, resetAllErrorMutation} from "../../../shared/helpers/mutation";
import {useResetBoardConfigMutation} from "../../../mutations/customer/ResetCustomerBoardConfig";
import {useQueryClient} from "react-query";
import WiFiAttackProtectionBoardsForm from '../../Configuration/Boards/components/WiFiAttackProtectionBoardsForm';

// const _ = require("lodash");


export const SECT_BLOCK_LIST_CONFIG = 0
export const SECT_VPN_ZERO_CONFIG = 1
export const SECT_WIFI_PROTECTION_CONFIG = 2

const BoardConfigSection = ({boardId, companyId, hasCustomConfig}) => {
  const config = [];
  const [overview, setOverview] = useState(SECT_BLOCK_LIST_CONFIG);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [areToastLoadingMutex, setAreToastLoadingMutex] = useState(false);
  const { data: dataConfig, isIdle: isIdleConfig, isLoading: isLoadingConfig, isError: isErrorConfig } = useBoardConfigQuery(boardId);
  const { data: dataBlockListDomain, isIdle: isIdleBlockListDomain, isLoading: isLoadingBlockListDomain, isError: isErrorBlockListDomain } = useBoardBlockListDomainQuery(boardId);
  const { data: dataBlockListIP, isIdle: isIdleBlockListIP, isLoading: isLoadingBlockListIP, isError: isErrorBlockListIP } = useBoardBlockListIPQuery(boardId);
  const { data: dataBlockListDomainCompany, isIdle: isIdleBlockListDomainCompany, isLoading: isLoadingBlockListDomainCompany, isError: isErrorBlockListDomainCompany } = useCompanyBlockListDomainQuery(companyId);
  const { data: dataBlockListIPCompany, isIdle: isIdleBlockListIPCompany, isLoading: isLoadingBlockListIPCompany, isError: isErrorBlockListIPCompany } = useCompanyBlockListIPQuery(companyId);

  const mutationBoardConfig = useUpdateBoardConfigMutation();
  const mutationBoardBlockListDomain = useUpdateBoardBlockListPolicyDomainMutation();
  const mutationBoardBlockListIP = useUpdateBoardBlockListPolicyIPMutation();
  const mutationResetBoard = useResetBoardConfigMutation();

  const queryClient = useQueryClient();

  // Refactor: lint warning about miossing deps, cant use objects as deps
  useEffect(() => {
    if (!areAllMutation('isIdle', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
      if (areAnyMutation('isLoading', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
        if (!areToastLoadingMutex) {
          setAreToastLoadingMutex(true);
          toast('Updating the configuration', {icon: '⏳', duration: Infinity});
        }
      } else {
        toast.dismiss();
        setAreToastLoadingMutex(false);
        if (areAnyMutation('isError', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
          toast(
            `Error: ${getErrorMessageFromResponse(getFirstMutationError(mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard))}`,
            {icon: '❌'}
          );
          resetAllErrorMutation(mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard);
        } else if (areAnyMutation('isSuccess', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
          toast('Configuration updated successfully', {icon: '✔️'});
          queryClient.refetchQueries(['board-config-customer', boardId]);
          queryClient.refetchQueries(['board-blocklist-ip', boardId]);
          queryClient.refetchQueries(['board-blocklist-domain', boardId]);
        }
      }
    }
  }, [
    boardId,
    mutationBoardBlockListDomain.isIdle,
    mutationBoardBlockListDomain.isLoading,
    mutationBoardBlockListIP.isIdle,
    mutationBoardBlockListIP.isLoading,
    mutationBoardConfig.isIdle,
    mutationBoardConfig.isLoading,
    mutationResetBoard.isIdle,
    mutationResetBoard.isLoading,
    queryClient,
    areToastLoadingMutex
  ]);

  useEffect(() => {
    if (areAnyMutation('isLoading', mutationBoardBlockListIP, mutationBoardBlockListDomain, mutationBoardConfig, mutationResetBoard)) {
      setDisabledSubmit(true);
    } else {
      setDisabledSubmit(false);
    }
  }, [
    mutationBoardBlockListDomain,
    mutationBoardBlockListIP,
    mutationBoardConfig,
    mutationResetBoard
  ]);


  if (isIdleBlockListDomain || isIdleBlockListIP || isIdleConfig || isLoadingBlockListDomain || isLoadingBlockListIP || isLoadingConfig
    || isIdleBlockListDomainCompany || isLoadingBlockListDomainCompany || isIdleBlockListIPCompany || isLoadingBlockListIPCompany) {
    return (
      <Col>
        <Card>
          <CardBody>
            <LoadingSpinner />
          </CardBody>
        </Card>
      </Col>
    )
  }

  if (isErrorBlockListDomain || isErrorConfig || isErrorBlockListIP || isErrorBlockListDomainCompany || isErrorBlockListIPCompany) {
    return (
      <Col>
        <Card>
          <CardBody>
            <h3>Unable to load the form</h3>
          </CardBody>
        </Card>
      </Col>
    )
  }

  const onSubmitBlockList = (data) => {
    const changedFields = getChangedFields(
      data,
      getListBlockConfiguration(dataBlockListDomain, dataBlockListIP),
      getListBlockConfiguration(dataBlockListDomainCompany, dataBlockListIPCompany)
    );

    if (!dataBlockListDomain && data.policyDomainsBL?.length > 0) {
      mutationBoardBlockListDomain.mutate({...{boardId: boardId}, ...{params: {blockedIndicators: data.policyDomainsBL.map((opt) => opt.value)}}})
    } else if (changedFields.policyDomainsBL) {
      mutationBoardBlockListDomain.mutate({...{boardId: boardId}, ...{params: {blockedIndicators: changedFields.policyDomainsBL}}})
    }

    if (!dataBlockListIP && data.policyIpsBL?.length > 0) {
      mutationBoardBlockListIP.mutate({...{boardId: boardId}, ...{params: {blockedIndicators: data.policyIpsBL.map((opt) => opt.value)}}})
    } else if (changedFields.policyIpsBL) {
      mutationBoardBlockListIP.mutate({...{boardId: boardId}, ...{params: {blockedIndicators: changedFields.policyIpsBL}}})
    }

    mutationBoardConfig.mutate({...{boardId: boardId}, ...{params: {data: {navigation: {enabled: !data.dropInternet},strict_dns_enabled:data.strictDnsEnabled, wifi_band:{override_24GHz_enabled:data.override24GHzEnabled}}}}});
  }

  const onSubmitVpnZeroTrust = (data) => {
    // FEATURE NOT AVAILABLE
    return;

    // const changedFields = getChangedFields(data, getVpnZeroTrustBoardConfiguration(config));

    // if (!_.isEmpty(changedFields)) {
    //   mutationBoardConfig.mutate({...{boardUid: boardId}, ...changedFields})
    // }
  }

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Button className="rounded" size="sm" outline
                  active={overview === SECT_BLOCK_LIST_CONFIG} onClick={() => setOverview(SECT_BLOCK_LIST_CONFIG)}
          >
            Block List
          </Button>
          {/*<Button*/}
          {/*  className="rounded" size="sm" outline disabled*/}
          {/*  active={overview === SECT_VPN_ZERO_CONFIG} onClick={() => setOverview(SECT_VPN_ZERO_CONFIG)}*/}
          {/*>*/}
          {/*  VPN & ZeroTrust*/}
          {/*</Button>*/}
          <Button
            className="rounded" size="sm" outline 
            active={overview === SECT_VPN_ZERO_CONFIG} onClick={() => setOverview(SECT_WIFI_PROTECTION_CONFIG)}
          >
            WiFi Attack Protection
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {
            (overview === SECT_BLOCK_LIST_CONFIG) ?
              <BoardConfigurationSubmit
                config={{...dataConfig.data, ...getListBlockConfiguration(dataBlockListDomain, dataBlockListIP)}}
                companyLists={getListBlockConfiguration(dataBlockListDomainCompany, dataBlockListIPCompany)}
                onSubmit={onSubmitBlockList}
                confId={boardId}
                title={`Board configuration`}
                hasCustomConfigBadge={hasCustomConfig}
                onResetConfiguration={() => mutationResetBoard.mutate(boardId)}
                disabled={disabledSubmit}
              />
              :(overview === SECT_VPN_ZERO_CONFIG) ?
              <VpnZeroTrustBoardSubmit
                config={config}
                onSubmit={onSubmitVpnZeroTrust}
                title={`Board configuration`}
              />
              :
              <WiFiAttackProtectionBoardsForm
                title={`Board ${boardId}`}
                boardId={boardId}
              />
          }
        </Col>
      </Row>
    </Fragment>
  );
}

BoardConfigSection.propTypes = {
  boardId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  hasCustomConfig: PropTypes.bool.isRequired
}

export default BoardConfigSection;
