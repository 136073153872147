import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row} from "reactstrap";
import BoardConfigurationSubmit from "../../../../shared/components/form/BoardConfigurationSubmit";
import VpnZeroTrustCompanySubmit from "./VpnZeroTrustCompanySubmit";
import {SECT_BLOCK_LIST_CONFIG, SECT_VPN_ZERO_CONFIG} from "../index";
import {useCompanyBlockListDomainQuery} from "../../../../queries/blocklist/company/CompanyBlockListPolicyDomain";
import {useCompanyBlockListIPQuery} from "../../../../queries/blocklist/company/CompanyBlockListPolicyIP";
import {useCompanyConfigQuery} from "../../../../queries/customer/CustomerCompanyConfig";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import {useUpdateCompanyConfigMutation} from "../../../../mutations/customer/UpdateCustomerCompanyConfig";
import {useUpdateCompanyBlockListPolicyDomainMutation} from "../../../../mutations/blocklist/company/UpdateCompanyBlockListPolicyDomain";
import {useUpdateCompanyBlockListPolicyIPMutation} from "../../../../mutations/blocklist/company/UpdateCompanyBlockListPolicyIP";
import {getChangedFields, getErrorMessageFromResponse, getListBlockConfiguration} from "../../../../shared/helpers";
import {toast} from "react-hot-toast";
import {areAllMutation, areAnyMutation, getFirstMutationError, resetAllErrorMutation} from "../../../../shared/helpers/mutation";
import {useQueryClient} from "react-query";
import WiFiAttackProtectionCompanyForm from "./WiFiAttackProtectionCompanyForm";


// const _ = require('lodash')


const CompanyConfigurationForms = ({companyId, overview}) => {
  const [areToastLoadingMutex, setAreToastLoadingMutex] = useState(false);
  const { data: dataConfig, isIdle: isIdleConfig, isLoading: isLoadingConfig, isError: isErrorConfig } = useCompanyConfigQuery(companyId);
  const { data: dataBlockListDomain, isIdle: isIdleBlockListDomain, isLoading: isLoadingBlockListDomain, isError: isErrorBlockListDomain } = useCompanyBlockListDomainQuery(companyId);
  const { data: dataBlockListIP, isIdle: isIdleBlockListIP, isLoading: isLoadingBlockListIP, isError: isErrorBlockListIP } = useCompanyBlockListIPQuery(companyId);

  const mutationCompanyConfig = useUpdateCompanyConfigMutation();
  const mutationCompanyBlockListDomain = useUpdateCompanyBlockListPolicyDomainMutation();
  const mutationCompanyBlockListIP = useUpdateCompanyBlockListPolicyIPMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!areAllMutation('isIdle', mutationCompanyBlockListIP, mutationCompanyBlockListDomain, mutationCompanyConfig)) {
      if (areAnyMutation('isLoading', mutationCompanyBlockListIP, mutationCompanyBlockListDomain, mutationCompanyConfig)) {
        if (!areToastLoadingMutex) {
          setAreToastLoadingMutex(true);
          toast('Updating the configuration', {icon: '⏳', duration: Infinity});
        }
      } else {
        toast.dismiss();
        setAreToastLoadingMutex(false);
        if (areAnyMutation('isError', mutationCompanyBlockListIP, mutationCompanyBlockListDomain, mutationCompanyConfig)) {
          toast(
            `Error: ${getErrorMessageFromResponse(getFirstMutationError(mutationCompanyBlockListIP, mutationCompanyBlockListDomain, mutationCompanyConfig))}`,
            {icon: '❌'}
          );
          resetAllErrorMutation(mutationCompanyBlockListIP, mutationCompanyBlockListDomain, mutationCompanyConfig);
        } else if (areAnyMutation('isSuccess', mutationCompanyBlockListIP, mutationCompanyBlockListDomain, mutationCompanyConfig)) {
          toast('Configuration updated successfully', {icon: '✔️'});
          queryClient.refetchQueries(['company-config-customer', companyId]);
          queryClient.refetchQueries(['company-blocklist-ip', companyId]);
          queryClient.refetchQueries(['company-blocklist-domain', companyId]);
        }
      }
    }
  }, [
    areToastLoadingMutex,
    companyId,
    mutationCompanyBlockListDomain.isIdle,
    mutationCompanyBlockListDomain.isLoading,
    mutationCompanyBlockListDomain.isError,
    mutationCompanyBlockListIP.isIdle,
    mutationCompanyBlockListIP.isLoading,
    mutationCompanyBlockListIP.isError,
    mutationCompanyConfig.isIdle,
    mutationCompanyConfig.isLoading,
    mutationCompanyConfig.isError
  ]);

  const onSubmitVpnZeroTrust = (data) => {
    // FEATURE NOT AVAILABLE
    return;

    // const changedFields = getChangedFields(data, dataConfig);

    // if (!_.isEmpty(changedFields)) {
    //   mutationCompanyConfig.mutate({...{companyId: companyId}, ...changedFields})
    // }
  }

  const onSubmitBlockList = (data) => {
    const changedFields = getChangedFields(data, getListBlockConfiguration(dataBlockListDomain, dataBlockListIP));

    if (!dataBlockListDomain && data.policyDomainsBL?.length > 0) {
      mutationCompanyBlockListDomain.mutate({...{companyId: companyId}, ...{params: {blockedIndicators: data.policyDomainsBL.map((opt) => opt.value)}}})
    } else if (changedFields.policyDomainsBL) {
      mutationCompanyBlockListDomain.mutate({...{companyId: companyId}, ...{params: {blockedIndicators: changedFields.policyDomainsBL}}})
    }

    if (!dataBlockListIP && data.policyIpsBL?.length > 0) {
      mutationCompanyBlockListIP.mutate({...{companyId: companyId}, ...{params: {blockedIndicators: data.policyIpsBL.map((opt) => opt.value)}}})
    } else if (changedFields.policyIpsBL) {
      mutationCompanyBlockListIP.mutate({...{companyId: companyId}, ...{params: {blockedIndicators: changedFields.policyIpsBL}}})
    }
    mutationCompanyConfig.mutate({...{companyId: companyId}, ...{params: {data: {strict_dns_enabled:data.strictDnsEnabled, wifi_band:{override_24GHz_enabled:data.override24GHzEnabled}}}}});
    // mutationCompanyConfig.mutate({...{companyId: companyId}, ...{params: {data: {navigation: {enabled: !data.dropInternet}}}}});
  }
  
  if (isIdleBlockListDomain || isIdleBlockListIP || isIdleConfig || isLoadingBlockListDomain || isLoadingBlockListIP || isLoadingConfig) {
    return <LoadingSpinner />
  }

  if (isErrorBlockListDomain || isErrorConfig || isErrorBlockListIP) {
    return (
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h3>Unable to load the form</h3>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col md={12}>
        {
          (overview === SECT_BLOCK_LIST_CONFIG) ?
            <BoardConfigurationSubmit
              config={{...dataConfig.data, ...getListBlockConfiguration(dataBlockListDomain, dataBlockListIP)}}
              confId={companyId}
              onSubmit={onSubmitBlockList}
              listsOnly
            />
            : (overview === SECT_VPN_ZERO_CONFIG) ?
              <VpnZeroTrustCompanySubmit
                config={dataConfig}
                onSubmit={onSubmitVpnZeroTrust}
              />
            : <WiFiAttackProtectionCompanyForm
                companyId={companyId}
                dataConfigWhitelist={dataConfig.data?.mac_whitelist}
              />
        }
      </Col>
    </Row>
  )
}


CompanyConfigurationForms.propTypes = {
  companyId: PropTypes.string.isRequired,
  overview: PropTypes.number.isRequired,
}

export default CompanyConfigurationForms;
