import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import Layout from '../../Layout/index';
import { useAuth0 } from '@auth0/auth0-react';
import MainWrapper from '../MainWrapper';

import LogIn from '../../LogIn';
import PrivateRoute from "../../../shared/components/router/PrivateRoute";
import paths from "../../../config/paths"
import auth0Config from "../../../config/auth0";
import Dashboard from "../../Dashboards";
import DogmaBoards from "../../DogmaBoards";
import DogmaBoardDetails from "../../DogmaBoardsDetails";
import Events from "../../Events";
import EventsPage from "../../Events/components/EventsOldPage";
import NotFound404 from "../../DefaultPage/404";
import ConfigurationGroups from "../../Configuration/Groups";
import ConfigurationCompany from "../../Configuration/Company";
import ConfigurationGroupsDetails from "../../Configuration/GroupsDetails";
import ConfigurationBoards from "../../Configuration/Boards";
import ConfigurationEmitters from "../../Configuration/Emitters";
import EmitterDetails from "../../Configuration/EmitterDetails";
import Policy from "../../Configuration/Policy";
import VPN from "../../VPN";
import ZeroTrust from "../../ZeroTrust";
import CompanyCodeLogin from '../../LogIn/company-code';

const AuthedRoute = () => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    return (
      <LogIn />
    )
  }

  if (isAuthenticated) {
    window.pendo.initialize({
      visitor: {
        id: user.sub
      },
      account: {
        id: 'security-dashboard',
        region: auth0Config.domain
      },
      excludeAllText: true,
      disableGuides: true
    });
  }

  return (
    <Navigate replace to="/" />
  )
};

const AuthedCompanyCode = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    return (
      <CompanyCodeLogin />
    )
  }

  return (
    <Navigate replace to="/" />
  )
};

const Router = (props) => {
  const SentryRoutes = props.routes
  const location = useLocation();
  return (
    <MainWrapper location={location}>
      <main>
        <SentryRoutes>
          <Route path={ paths.loginPath } element={<AuthedRoute />} />
          <Route path={ paths.companyCodePath } element={<AuthedCompanyCode />} />
          <Route element={<Layout />}>
            <Route path={ paths.homePath } element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path={paths.boardsPath} element={
              <PrivateRoute>
                <DogmaBoards />
              </PrivateRoute>
            } />
            <Route path={paths.boardDetailsPath} element={
              <PrivateRoute>
                <DogmaBoardDetails boardsUrl={paths.boardsPath}/>
              </PrivateRoute>
            } />
            <Route path={paths.eventsPath} element={
              <PrivateRoute>
                <Events />
              </PrivateRoute>
            } />
            {/*<Route path="/events_old" element={*/}
            {/*  <PrivateRoute>*/}
            {/*    <EventsPage />*/}
            {/*  </PrivateRoute>*/}
            {/*} />*/}
            <Route path={paths.configurationGroupDetailsPath} element={
              <PrivateRoute>
                <ConfigurationGroupsDetails />
              </PrivateRoute>
            } />
            <Route path={paths.configurationGroupsPath} element={
              <PrivateRoute>
                <ConfigurationGroups />
              </PrivateRoute>
            } />
            <Route path={paths.configurationCompanyPath} element={
              <PrivateRoute>
                <ConfigurationCompany />
              </PrivateRoute>
            } />
            <Route path={paths.configurationBoardsPath} element={
              <PrivateRoute>
                <ConfigurationBoards />
              </PrivateRoute>
            } />
            <Route path={paths.configurationEmitterDetailPath} element={
              <PrivateRoute>
                <EmitterDetails />
              </PrivateRoute>
            } />
            <Route path={paths.configurationEmittersPath} element={
              <PrivateRoute>
                <ConfigurationEmitters />
              </PrivateRoute>
            } />
{/*            <Route path={paths.vpnPath} element={
              <PrivateRoute>
                <VPN />
              </PrivateRoute>
            } />
            <Route path={paths.zeroTrustPath} element={
              <PrivateRoute>
                <ZeroTrust />
              </PrivateRoute>
            } />*/}
            <Route path={paths.policyConfigurationPath} element={
              <PrivateRoute>
                <Policy />
              </PrivateRoute>
            } />
            <Route path="*" element={<NotFound404 />} />
          </Route>
        </SentryRoutes>
      </main>
    </MainWrapper>
  )
};

export default Router;
