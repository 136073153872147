import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CompanyConfigurationForms from "./components/CompanyConfigurationForms";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import { useUserInfo, ROLE_SUPERADMIN } from "../../../shared/helpers/auth";
import AppError from "../../DefaultPage/AppError";
import ToggleButton from '../../../shared/components/form/components/ToggleButton';
import { toast } from "react-hot-toast";
import { useCompanyConfigQuery, configurationCompanyFetcher } from '../../../queries/customer/CustomerCompanyConfig'
import { UpdateStrictModeFetcher, useUpdateStrictMode } from '../../../mutations/customer/UpdateCustomerStrictMode';
import { useAuth0AccessToken } from '../../../queries';
import { useQueryClient } from "react-query";
import { WithConfirmationModal } from '../../../shared/hocs/WithConfirmationModal';
import { listCompanyFetcher, useListCompanyQuery } from '../../../queries/CompanyList';

const ToggleWithConfirmationModal = WithConfirmationModal(ToggleButton, "onClick");

export const SECT_BLOCK_LIST_CONFIG = 0
export const SECT_VPN_ZERO_CONFIG = 1
export const SECT_WIFI_PROTECTION_CONFIG = 2

const ConfigurationCompany = () => {
  const queryClient = useQueryClient();
  const { data: companyList, isIdle: isIdleList, isLoading: isLoadingList, isError: isErrorList } = useListCompanyQuery()
  const { companyId: userCompanyId, isLoading: isLoadingUserInfo, error: isErrorUserInfo, hasRole, roles } = useUserInfo();
  const [currentCompanyId, setCurrentCompanyId] = useState(userCompanyId);
  const [overview, setOverview] = useState(SECT_BLOCK_LIST_CONFIG);
  const { data: companyConfig, isIdle: isIdleConfig, isLoading: isLoadingConfig, isError: isErrorConfig } = useCompanyConfigQuery(currentCompanyId);
  const [strictModeStatusTxt, setStrictModeStatusTxt] = useState()
  const [strictMode, setStrictMode] = useState(false)
  const [superAdmin, setSuperAdmin] = useState()
  const { data: accessToken, isError: tokenIsError, isLoading: tokenIsLoading, isIdle: tokenIsIdle } = useAuth0AccessToken()
  const mutationStrictMode = useUpdateStrictMode();


  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState({ id: null, name: 'Select Company' })
  const toggle = () => { setDropdownOpen((prevState) => !prevState) };

  useEffect(() => {
    setCurrentCompanyId(hasRole(ROLE_SUPERADMIN) && selectedCompanyId.id ? selectedCompanyId.id : userCompanyId)
  }, [userCompanyId, selectedCompanyId])

  useEffect(() => {
    if(companyList){
      let currentCompany = companyList.filter(company => company.id === currentCompanyId )
      changeCompany(currentCompany[0])
    }
  },[userCompanyId, companyList])

  const changeCompany = (value) => {
    setSelectedCompanyId(value)
  }


  const changeTextStrict = (value) => {
    setStrictModeStatusTxt(value ? 'Enabled' : 'Disabled')
  }

  useEffect(() => {
    setSuperAdmin(hasRole(ROLE_SUPERADMIN))
  }, [roles])

  useEffect(() => {
    if (!isLoadingConfig && !isErrorConfig) {
      let placeholder = companyConfig?.data?.strict_mode?.enabled
      setStrictMode(placeholder)
      changeTextStrict(companyConfig?.data?.strict_mode?.enabled)
    }
  }, [companyConfig, isLoadingConfig, isErrorConfig]
  )

  const toggleStrictMode = () => {
    toast('Updating the configuration', { icon: '⏳', duration: Infinity });
    UpdateStrictModeFetcher(accessToken, selectedCompanyId.id, !strictMode).then(
      res => {
        toast.dismiss();
        toast('Configuration updated successfully', { icon: '✔️' });
        setStrictMode(current => !current)
        let placeholder = strictMode
        changeTextStrict(!placeholder)
      }
    ).catch(
      error => {
        toast(`Error: ${error.request.response.replace(']', '').replace('[', '')}.`, { icon: '❌' });
      }
    )
  }

  if (isLoadingUserInfo, isLoadingConfig, isLoadingList) {
    return <LoadingSpinner />
  }

  if (isErrorUserInfo) {
    console.error('Could not load user data or user data has some missing information');
    return <AppError />;
  }

  return (
    <Container className="devices-container">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Configuration Company Boards</h3>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Button className="rounded" size="sm" outline
            active={overview === SECT_BLOCK_LIST_CONFIG} onClick={() => setOverview(SECT_BLOCK_LIST_CONFIG)}
          >
            Block List
          </Button>
          {/*<Button*/}
          {/*  className="rounded" size="sm" outline disabled*/}
          {/*  active={overview === SECT_VPN_ZERO_CONFIG} onClick={() => setOverview(SECT_VPN_ZERO_CONFIG)}*/}
          {/*>*/}
          {/*  VPN & ZeroTrust*/}
          {/*</Button>*/}
          <Button
            className="rounded" size="sm" outline
            active={overview === SECT_WIFI_PROTECTION_CONFIG} onClick={() => setOverview(SECT_WIFI_PROTECTION_CONFIG)}
          >
            WiFi Attack Protection
          </Button>
          {superAdmin && (
            <>
              <Row >
                <Col md={9} className={'float-left'}>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                    <DropdownToggle caret color="dark" size="sm">{selectedCompanyId.name}</DropdownToggle>
                    <DropdownMenu dark>
                      {companyList.map(el =>
                        (<DropdownItem key={el.id} onClick={() => changeCompany(el)}>{el.name}</DropdownItem>)
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </Col>

                {/* <Col md={5}>
              
                  </Col>*/}
                <Col md={2} className={'float-right'}>
                  <p>Strict Mode: <span className='fw-bold'>{strictModeStatusTxt || ''}</span></p>
                </Col>
                <Col md={1} className={'float-right'}>
                  <ToggleWithConfirmationModal
                    checked={strictMode || ''}
                    onConfirm={toggleStrictMode}
                    modalEnabled={true}
                    titleModal={'Confirm the operation'}
                    confirmText={'Are you sure you want to ' + (strictMode ? 'disable' : 'enable') + ' the Strict mode setting?'} />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <CompanyConfigurationForms
        companyId={currentCompanyId}
        overview={overview}
      />
    </Container>
  );
}

export default ConfigurationCompany;
