import {Badge, Button, Card, CardBody, Col, Row} from "reactstrap";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";
import {getDefaultFormConfiguration} from "../../helpers";
import BoardConfigurationFieldsForm from "./BoardConfigurationFieldsForm";
import ConfirmButtonModal from "../modals/ConfirmButtonModal";


// listsOnly to remove due to bug on configuration distribution
const BoardConfigurationSubmit = ({
  config,
  onSubmit,
  confId,
  title = '',
  companyLists = [],
  listsOnly = false,
  hasCustomConfigBadge = false,
  onResetConfiguration = null,
  disabled = false
}) => {
  const {register, handleSubmit, formState: {errors}, control, watch, setValue} = useForm({
    defaultValues: getDefaultFormConfiguration(config, companyLists)
  });

  // TODO: using the required deps for this block causes a cyclical render bug, look for a refactor
  useEffect(() => {
    for (const [key, val] of Object.entries(getDefaultFormConfiguration(config, companyLists))) {
      setValue(key, val);
    }
  }, [confId])
  return (
    <Card style={{height: 'auto'}}>
      <CardBody>
        {
          title && <div className="card__title">
            <h4 className={'bold-text d-inline'}>
              {title}
              {hasCustomConfigBadge && <Badge className={"outline float-right status-badge"} color={'success'}>CUSTOM CONFIGURATION</Badge>}
            </h4>
          </div>
        }
        <Row>
          <Col md={12}>
            <form className="form form--vertical w-100">
              <BoardConfigurationFieldsForm
                control={control}
                register={register}
                errors={errors}
                watch={watch}
                listsOnly={listsOnly}
              />
            </form>
          </Col>
        </Row>
        <Row>
          <Col className={'mt-3'}>
            {
              onResetConfiguration &&
              <ConfirmButtonModal
                buttonLabel={'Reset configuration'}
                onConfirm={onResetConfiguration}
                titleModal={'Confirm the operation'}
                confirmText={'Are you sure to reset the configuration of the board?'}
                color={'danger'}
                float={'left'}
                disabled={disabled}
              />
            }
            {
              watch('dropInternet') && !listsOnly ?
              <ConfirmButtonModal
                buttonLabel={'Update configuration'}
                onConfirm={handleSubmit(onSubmit)}
                titleModal={'Confirm the operation'}
                confirmText={'Are you sure to disable internet on the board?'}
                disabled={disabled}
              /> :
              <Button outline className={'float-right'} onClick={handleSubmit(onSubmit)} color={'success'} disabled={disabled}>Update configuration</Button>
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

BoardConfigurationSubmit.propTypes = {
  config: PropTypes.shape().isRequired,
  confId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  companyLists: PropTypes.shape(),
  listsOnly: PropTypes.bool,
  hasCustomConfigBadge: PropTypes.bool,
  disabled: PropTypes.bool,
  onResetConfiguration: PropTypes.func
}


export default BoardConfigurationSubmit;
